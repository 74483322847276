import React from 'react'

const SvgAboutQualificationIconm6 = (props) => (
  <svg viewBox='0 0 18 41' {...props}>
    <path
      fillRule='evenodd'
      fill='#2DBEC3'
      d='M17.175 36.12h-1.284v-7.275a.651.651 0 00-.642-.661h-3.84c-.195-.461-.48-1.284-.48-1.721 0-.367.245-1.25.425-1.896.153-.553.285-1.032.333-1.362.074-.516-.054-1.41-.227-2.527a13.812 13.812 0 01-.124-.898c.005-.09.085-.381.15-.616.174-.626.411-1.486.411-2.373 0-.95-.084-2.451-.128-3.189.148-.106.313-.256.483-.463.523-.646 1.432-4.467.995-5.807-.294-.897-1.374-1.482-2.243-1.953-.161-.087-.363-.196-.502-.281.038-.163.103-.39.156-.573.19-.661.405-1.41.309-1.981C10.779 1.427 9.855.737 9.047.737c-.758 0-1.693.69-1.88 1.806-.096.57.115 1.315.302 1.974.052.182.115.409.153.573-.139.085-.341.194-.503.281-.87.47-1.952 1.056-2.247 1.955-.438 1.341.469 5.163.993 5.806.168.208.333.356.482.462-.045.737-.128 2.229-.128 3.179 0 .888.236 1.74.409 2.362.065.232.145.521.149.605 0 .111-.069.546-.124.895-.173 1.1-.299 1.983-.226 2.498.046.325.177.808.328 1.368.181.671.43 1.59.43 1.962 0 .437-.285 1.26-.48 1.721H3.052a.652.652 0 00-.642.661v7.275H1.127a.652.652 0 00-.642.661v3.306c0 .366.288.662.642.662h16.048a.652.652 0 00.642-.662v-3.306a.651.651 0 00-.642-.661zm-5.136 0H6.262v-3.968h5.777v3.968z'
    />
  </svg>
)

export default SvgAboutQualificationIconm6
