import React from 'react'

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='96' height='24' viewBox='0 0 120 30'>
      <path
        fill='#FFF'
        fillRule='evenodd'
        d='M117.82 19.652L120 23.17v4.522H96.929V23.17l2.325-6.515h-2.325V12.39H120v4.265h-13.246l-2.325 6.515h8.045l-2.214-3.518h7.56zM98.81 4.615h20.035V9.23H98.81V4.615zM85.8 27.692h-7.345l4.916-13.462H94.63v4.674h-5.866l-1.519 4.266h7.385v4.522h-8.975.145zm-9.697 0h-5.128v-6.058l3.514-9.084h-3.504V7.439h5.197l1.036-2.824h6.313v2.824h2.439V4.615h5.768v2.824h2.812v5.111H81.838l-5.735 15.142zm-13.158 0h-7.45l.995-2.382H45.23v-4.615h11.894l3.744-16.08h7.342l-3.726 16.08h4.446v4.615h-5.461l-.524 2.382zM47.944 20l1.957-6.808H47.87l1.681-8.577h7.151l-1.955 6.807h2.814L47.944 20zm-7.19-.58c-.345-1.654-1.031-2.812-1.984-3.728 1.432 2.563 2.007 6.717.047 8.735a7.606 7.606 0 00-.132-2.317s-.103 7.774-11.833 7.889l-.006.001h-.25c-9.015 0-16.6-7.25-16.6-7.25l4.341-2.128L0 13.32l23.5 7.427-5.666 2s11.816 3.615 13.816-.8c-.42-.365-.334-.759-.316-1.159l.001-.091v-.03-.043c-.005-2.965-3.862-6.027-4.891-6.204l-1.025 4c-5.758-4.565-6.609-3.878-7.558-1.51-1.456-.3-2.414-2.347-2.574-4.025 0 0 2.517 1.695 3.577-.036 1.746-1.947 3.658-1.33 3.658-1.33-2.756-1.784-6.338-2.249-6.723-1.682-.399.852.893 1.88.893 1.88-2.035-.032-3.334-1.96-3.334-1.96-.58.44-.225 1.637-.225 1.637-1.152-1.219-1.692-2.705-1.362-4.659 2.574.497 4.693.375 6.883-.285l.469-2.091 5.869-.837s5.34-.723 8.345-3.522c0 0-1 2.824-3.337 4.27 2.46.051 4.863-.743 6.808-2.07 0 0-.987 2.592-3.949 3.512 3.241.945 5.451 2.537 6.396 5.548-1.294-.908-2.73-1.595-3.655-2.033 0 0 6.046 3.18 5.154 10.193zM20.156 6.45l1.087.82a6.786 6.786 0 012.129-1.286l1.152-.422-4.368.888z'
      ></path>
    </svg>
  )
}

export default Icon
