import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-react-intl'
import React from 'react'
import {
  CopyrightInfo,
  FooterContainer,
  FooterIconListOutterContainer,
  FooterLinkContainer,
} from './footer.atom'

import { LangType } from '../../constant'

const FooterLinkList = [
  {
    title: 'menu_about',
    to: '/about.html',
  },
  {
    title: 'menu_case',
    to: '/case.html',
  },
  {
    title: 'menu_contact',
    to: '/about.html#about-bottom',
  },
  {
    title: 'menu_join',
    to: 'https://rayvision.zhiye.com/',
    onlyCn: true,
  },
]

// const FooterIconList = [
//   {
//     icon: <WeChat />,
//     qrCode: WechartQrCode,
//   },
//   {
//     icon: <Weibo />,
//     staticIcon: <WhiteWeibo />,
//     href: 'https://www.weibo.com/renderbus',
//   },
//   {
//     icon: <Facebook />,
//     href: 'https://www.facebook.com/rayvision.inc',
//   },
//   {
//     icon: <Linkedin />,
//     href: 'https://www.linkedin.com/company/foxrenderfarm/',
//   },
// ]

// const transitionStyles = {
//   entering: { opacity: 0 },
//   entered: { opacity: 1 },
//   exiting: { opacity: 0 },
//   exited: { opacity: 0, display: 'none' },
// }

export default React.memo(function Footer() {
  // const [hoverIndex, setHoverIndex] = useState(-1)
  const intl = useIntl()
  const IsCN = intl.locale === LangType.CN

  return (
    <FooterContainer
      style={{
        justifyContent: IsCN ? 'space-between' : 'center',
        textAlign: IsCN ? 'left' : 'center',
      }}
    >
      <CopyrightInfo>
        <FormattedMessage id='copyright.info' />{' '}
        <a target='_blank' rel='noreferrer' href='http://beian.miit.gov.cn'>
          <FormattedMessage id='copyright.no' />
        </a>
      </CopyrightInfo>
      {IsCN && (
        <>
          <FooterLinkContainer>
            {FooterLinkList.map((info) => (
              <Link to={info.to} key={info.title}>
                <FormattedMessage id={info.title} />
              </Link>
            ))}
          </FooterLinkContainer>
          <FooterIconListOutterContainer>
            <FormattedMessage id='copyright.licence' />
          </FooterIconListOutterContainer>
        </>
      )}
    </FooterContainer>
  )
})
