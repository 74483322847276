import * as React from 'react'

function MouseIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 50' {...props}>
      <g data-name='\u56FE\u5C42 2'>
        <g data-name='\u56FE\u5C42 1'>
          <rect
            x={1}
            y={1}
            width={32}
            height={48}
            rx={16}
            fill='none'
            stroke='#fff'
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <rect x={15} y={12.07} width={4} height={8} rx={1.94} fill='#fff' />
        </g>
      </g>
    </svg>
  )
}

export default MouseIcon
