import React from 'react'

const JoinUsKonw3 = (props) => (
  <svg viewBox='0 0 63 60' {...props}>
    <path
      fillRule='evenodd'
      fill='#02A6BC'
      d='M5.048-.005h52.901a4.061 4.061 0 014.07 4.054v51.9a4.063 4.063 0 01-4.07 4.056H5.048a4.063 4.063 0 01-4.07-4.056v-51.9a4.061 4.061 0 014.07-4.054zm0 4.054v51.9h52.901v-51.9H5.048zm28.476 37.586c.006.04.009.082.009.124v8.514a.812.812 0 01-.814.811h-2.442a.812.812 0 01-.813-.811v-8.514c0-.044.003-.086.009-.127-9.844-3.183-14.821-11.5-14.821-24.445a2.03 2.03 0 012.196-2.021l8.271.657L30 10.526a2.038 2.038 0 012.99-.007l4.933 5.305 8.394-.658a2.032 2.032 0 012.193 2.073c-.326 12.723-5.351 21.006-14.986 24.396zm-1.979-3.614c7.774-2.478 12.008-8.558 12.783-18.632l-7.068.554a2.037 2.037 0 01-1.652-.643l-4.103-4.413-4.06 4.406c-.423.461-1.036.7-1.66.65l-7.011-.557c.513 10.317 4.737 16.377 12.771 18.635zm-19.686 6.863a2.04 2.04 0 01-2.873.169 2.022 2.022 0 01-.17-2.862c6.021-6.751 12.618-5.044 18.311 4.181a2.024 2.024 0 01-.69 2.784 2.039 2.039 0 01-2.776-.66c-2.278-3.689-4.437-5.723-6.332-6.214-1.672-.432-3.441.326-5.47 2.602zm39.095.008c-2.001-2.284-3.734-3.04-5.363-2.611-1.863.49-3.996 2.535-6.247 6.247a2.04 2.04 0 01-2.794.686 2.023 2.023 0 01-.689-2.783c5.633-9.288 12.192-11.016 18.158-4.206.74.844.653 2.125-.194 2.861a2.04 2.04 0 01-2.871-.194z'
    />
  </svg>
)

export default JoinUsKonw3
