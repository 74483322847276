import * as React from 'react'

function Join13(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={42} height={40} {...props}>
      <path
        fillRule='evenodd'
        fill='#FFF'
        d='M.606 40V15.55l.03.21v-.34L5.7 12.931V0h31.499v12.952l4.346 2.728.055-.31V40H.606zM5.7 15.323l-1.86.93 1.86.93v-1.86zM35.81 2.162H7.125v16.042l14.186 7.366 14.499-7.314V2.162zm1.389 13.183v1.868l1.902-.942-1.902-.926zm2.284 3.14L21.32 27.458 2.724 18.473v19.346h36.759V18.485zm-28.002-1.236c0-.495.464-1.092.957-1.092h12.759c.494 0 .91.601.91 1.092 0 .497-.389 1.093-.91 1.093H12.438c-.489 0-.957-.596-.957-1.093zm-.258-5.03c0-.494.464-1.092.958-1.092H30.74c-.172 0 .24.598.244 1.092 0 .497-.416 1.093-.244 1.093H12.181c-.49 0-.958-.596-.958-1.093zM30.74 8.282H12.181c-.49 0-.958-.596-.958-1.092 0-.496.464-.22.958-.22H30.74c-.172 0 .24-.276.244.22 0 .496-.416 1.092-.244 1.092z'
      />
    </svg>
  )
}

export default Join13
