import * as React from 'react'

function PlayIcon(props) {
  return (
    <svg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M681.824 475.488L446.208 342.816A53.344 53.344 0 00420.832 336C400.16 336 384 349.888 384 368.192V655.84c0 18.208 16.16 32.16 36.832 32.16 8 0 16.672-2.048 25.376-6.816l235.616-132.672c13.504-8.608 22.176-24.064 22.176-37.28s-8.672-27.136-22.176-35.744zM512 0C229.248 0 0 229.248 0 512c0 282.784 229.248 512 512 512 282.784 0 512-229.216 512-512S794.784 0 512 0zm0 992C247.328 992 32 776.672 32 512S247.328 32 512 32s480 215.328 480 480-215.328 480-480 480z' />
    </svg>
  )
}

export default PlayIcon
