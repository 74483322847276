import React from 'react'

const JoinUsStudent = (props) => (
  <svg {...props} viewBox='0 0 32 30'>
    <path
      fillRule='evenodd'
      fill='#FFF'
      d='M10.589 16.725c-.803-.05-1.37-.7-1.394-1.85.024-.7.591-1.25 1.394-1.25.827-.025 1.394.525 1.465 1.475.047.825-.733 1.65-1.465 1.625zm1.229-5.475c-.048-.9.591-1.65 1.37-1.65.756.025 1.441.725 1.465 1.5.023.85-.662 1.7-1.418 1.625-.826-.075-1.393-.5-1.417-1.475zm5.788 9.65a77.202 77.202 0 01-3.591-2.15c-.567-.35-.756-.95-.52-1.625.166-.55.567-.825 1.181-.9.19.05.426.075.639.175 1.181.65 2.363 1.325 3.521 2.025.732.45 1.039 1.15.59 2-.449.825-1.134.85-1.82.475zM14.299 7.2c0-.9.496-1.475 1.394-1.475.897 0 1.346.575 1.488 1.425-.118.9-.614 1.525-1.441 1.525-.827 0-1.417-.575-1.441-1.475zm4.465 1.675c.189-.625.638-.925 1.489-.875 1.347.625 2.954 1.15 4.371 2.15.497.375.544 1 .236 1.575-.33.6-.85.975-1.464.7a64.579 64.579 0 01-4.159-2.025c-.543-.3-.685-.9-.473-1.525zm1.844 7.775c-1.371-.6-2.694-1.325-3.994-2.1-.591-.375-.732-1.075-.378-1.75.331-.65.898-1 1.583-.675 1.3.65 2.576 1.375 3.875 2.1.402.225.591.625.591 1.125 0 .95-.803 1.675-1.677 1.3zm2.079 6.9c-1.654 2.625-3.308 5.275-6.309 6.45h-2.599c-.426-.6-1.111-.7-1.654-1.1-2.718-2.05-5.435-4.1-8.199-6.075-1.418-1-2.741-2.05-3.379-3.8-.048-1-.048-2-.048-3 .497-.375.308-1.1.875-1.65 3.899 2.75 7.655 5.825 12.121 7.825-.827 1.6-1.016 3.075.355 4.35.756.725 1.559.9 2.481.35a7.164 7.164 0 002.268-2.175c3.332-5.1 6.64-10.225 9.924-15.275-.212-.6-.638-.65-.969-.825-3.78-1.95-7.585-3.875-11.341-5.85-.78-.4-1.135-.25-1.584.5-2.173 3.625-4.418 7.2-6.639 10.775-.165.275-.331.55-.543.825-.284.35-.639.5-1.064.3-.449-.225-.591-.675-.425-1.175.118-.4.307-.775.52-1.1C8.911 9 11.345 5.125 13.779 1.25c.283-.45.638-.825.945-1.25h1.418c.236.15.448.3.709.425 4.04 1.875 8.104 3.75 12.144 5.625.969.45 1.914.975 2.505 1.95v1.25c-2.93 4.775-5.836 9.575-8.813 14.3z'
    />
  </svg>
)

export default JoinUsStudent
