import React from 'react'

const SvgAboutQualificationIconm7 = (props) => (
  <svg viewBox='0 0 140 104' {...props}>
    <path
      fillRule='evenodd'
      opacity={0.2}
      fill='#2BBFA5'
      d='M121.494 80.214l-19.17-8.241 18.177-8.08 19.161 8.08-18.168 8.241zM55.768 49.035l14.749-6.221 45.252 19.083-14.744 6.522-45.257-19.384zM35.623 69.558l-9.377-4.162 28.591-12.118 9.38 4.041-28.594 12.239zM18.962 80.473L.789 72.228l19.166-8.085 18.183 8.085-19.176 8.245zm44.734 7.35l-9.893 4.391-28.591-12.909 9.89-4.26 28.594 12.778zm12.781-.133l28.594-12.774 9.887 4.259-28.59 12.905-9.891-4.39zm12.641 7.208l-19.185 8.701-19.179-8.701 19.185-8.528 19.179 8.528z'
    />
    <path
      fillRule='evenodd'
      fill='#2BBFA5'
      d='M69.817 82.625L24.211 60.65l-.285-10.274 46.176 21.69 46.461-21.69v9.989l-46.746 22.26zM24.211 46.838l-.285-10.274 46.176 21.69 46.461-21.69v9.989l-46.746 22.26-45.606-21.975zm0-13.812l-.285-10.274 46.176 21.689 46.461-21.689v9.989l-46.746 22.26-45.606-21.975zm67.364-14.117l12.088-5.205 12.75 5.205-12.079 5.309-12.759-5.309zM60.61 4.139L70.418.135l30.096 12.285-9.803 4.199L60.61 4.139zM47.208 17.353l-6.236-2.681L59.991 6.87l6.237 2.601-19.02 7.882zm-.35 3.535l19.021 8.234-6.583 2.831-19.019-8.321 6.581-2.744zm46.547-.084l6.579 2.744-19.018 8.318-6.582-2.829 19.021-8.233zM57.266 33.682l12.767-5.497 12.764 5.497-12.768 5.611-12.763-5.611zm-21.148-9.297l-12.084-5.312 12.755-5.207 12.09 5.207-12.761 5.312z'
    />
  </svg>
)

export default SvgAboutQualificationIconm7
