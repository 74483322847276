import React from 'react'

const SvgIndexTeamIcon5 = (props) => (
  <svg viewBox='0 0 31.76 40' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M7.69 5.64a4.1 4.1 0 114.1 4.1 4.1 4.1 0 01-4.1-4.1zM30.22 0a1.54 1.54 0 00-1.55 1.55V3.6a1.55 1.55 0 003.09 0v-2A1.55 1.55 0 0030.22 0zm1.54 11.74v26.71A1.55 1.55 0 0130.22 40H1.55a1.55 1.55 0 110-3.09h6.14V14.52C3.21 12.56 0 7.51 0 1.57a1.55 1.55 0 113.09 0c0 4.85 2.62 8.9 6.19 10.24h5c3.57-1.34 6.19-5.39 6.19-10.24a1.55 1.55 0 113.09 0c0 5.94-3.2 11-7.69 13v22.34h12.84V11.74a1.56 1.56 0 011.55-1.55 1.53 1.53 0 011.5 1.55zm-21 25.17h2.05V24.1h-2.05zm15.36-13.84a1.55 1.55 0 001.55-1.55v-6.14a1.55 1.55 0 00-3.1 0v6.14a1.58 1.58 0 001.55 1.55z'
        fill='#fff'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgIndexTeamIcon5
