import React from 'react'

const SvgIndexTeamIcon3 = (props) => (
  <svg viewBox='0 0 40 33.09' {...props}>
    <defs>
      <style>{'.index-team-icon-3_svg__cls-1{fill:#fff}'}</style>
    </defs>
    <g id='index-team-icon-3_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='index-team-icon-3_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='index-team-icon-3_svg__cls-1'
          d='M29.07 14.38c.54-.43.31-1.56-.18-2L18.13 3.29l-7.6.1c-1.23 0-.65 1.1 0 1.61q4.44 3.73 8.89 7.46l-4.62 3.39c-.81.59-.12 2.24.48 2.74L33 33.09h7c-.2-.87-.11-2-.81-2.56l-15-12.17 4.93-4zM36.83 5.21a5 5 0 10-10 0 5 5 0 1010 0zM7 14.62l-7 .22c.06.55 0 1.85.41 2.19l11.31 9.45a2.24 2.24 0 002.06.29l3.28-2.19c.27-.17.54-1.12.27-1.33l-9.28-7.5L7 14.62z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIndexTeamIcon3
