import styled, { css } from 'styled-components'
import { color, fontSize, Media, spacing, transition } from '../../style/theme'

export const FooterContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 2;
  ${Media.phone`
      flex-direction: column-reverse;
      justify-content: flex-start;
      height: 100px;
      padding: 0 0 ${spacing.base};
    `}
`

export const CopyrightInfo = styled.span`
  flex-shrink: 0;
  color: ${color.textLight};
  font-size: ${fontSize.mini};
  white-space: nowrap;
  a {
    color: ${color.textLight};
  }
  ${Media.phone`
    width: 100%;
    font-size: ${fontSize.tiny};
    text-align: center;
  `}
`

export const FooterLinkContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  a {
    color: #bec3c5;
    font-size: ${fontSize.small};
    transition: ${transition('color')};
    :hover {
      color: white;
    }
  }
  a + a::before {
    content: '|';
    color: #bec3c5;
    margin: 0 ${spacing.large};
    pointer-events: none;
  }
  ${Media.phone`
    width: 100%;
    a {
      font-size: ${fontSize.tiny};
    }
    a + a::before {
      margin: 0 ${spacing.base};
    }
  `}
`

export const FooterIconListOutterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 33%;
  flex-shrink: 1;
  color: ${color.textLight};
  font-size: ${fontSize.mini};
  ${Media.phone`
    width: 100%;
    justify-content: center;
  `}
`

export const FooterIconListContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  ${Media.phone`
    width: 124px;
  `}
`

export const FooterIconTooltip = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 100%;
  right: 50%;
  transform: translateX(50%);
  transition: ${transition('opacity')};
  opacity: 0;
`

export const FooterIconContent = styled.div`
  position: relative;
  cursor: pointer;
  svg {
    height: 12px;
    path {
      transition: ${transition('fill')};
    }
    ${Media.phone`
      width: 15px;
    `}
    ${(p) =>
      p.hasStaticIcon &&
      css`
        :first-of-type {
          opacity: 1;
          transition: ${transition('opacity')};
        }
        &:last-of-type {
          position: absolute;
          top: 50%;
          right: 0;
          opacity: 0;
          transform: translateY(-50%);
          transition: ${transition('opacity')};
        }
      `}
  }
  &:not(:hover) {
    path {
      fill: white;
    }
    ${(p) =>
      p.hasStaticIcon &&
      css`
        svg:first-of-type {
          opacity: 0;
        }
        svg:last-of-type {
          opacity: 1;
        }
      `}
  }
`
