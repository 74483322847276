import React from 'react'

const SvgIndexTeamIcon2 = (props) => (
  <svg viewBox='0 0 31.12 40' {...props}>
    <defs>
      <style>{'.index-team-icon-10_svg__cls-1{fill:#fff}'}</style>
    </defs>
    <g id='index-team-icon-10_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='index-team-icon-10_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='index-team-icon-10_svg__cls-1'
          d='M31.12 16.61l-2.69-1.88a2.76 2.76 0 00-1.6-.45 3.71 3.71 0 00-2.73 1.19l-1 1.07-1-1.07a3.72 3.72 0 00-5.39-.07l-1.1 1.09-1-1.19a3.42 3.42 0 00-2.76-1 3.51 3.51 0 00-2.71 1L8 16.4l-1-1.16a3.48 3.48 0 00-2.7-1 3.64 3.64 0 00-1.7.33L0 16l.47-2.9A11.89 11.89 0 014.66 6a16.71 16.71 0 0110.89-3.77A16.75 16.75 0 0126.44 6a11.83 11.83 0 014.22 7.32zm-11.81-5.19a6.56 6.56 0 013.76 1.19 6.72 6.72 0 014.05-1.18 9.93 9.93 0 00-2.53-3.22 13.93 13.93 0 00-9-3.12 13.93 13.93 0 00-9 3.12A9.7 9.7 0 004 11.43h.29a6.75 6.75 0 013.76 1 6.75 6.75 0 013.76-1 6.7 6.7 0 013.89 1.1 6.57 6.57 0 013.63-1.1z'
        />
        <path
          className='index-team-icon-10_svg__cls-1'
          d='M28.14 30.44L15.76 18.06 3 30.35 1 28.3l14.8-14.24 14.36 14.36zM26.87 21.89H24v-2.2l-2.25-.05.05-2.85 5.05.1z'
        />
        <path
          className='index-team-icon-10_svg__cls-1'
          d='M14.55 40H6.08V28.81h2.85v8.34h2.77v-5.92h7.5v5.9h3v-8.32h2.86V40h-8.7v-5.9h-1.8zM14.12 0h2.85v3.66h-2.85z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIndexTeamIcon2
