import * as React from 'react'

function Join16(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={42} height={40} {...props}>
      <path
        fillRule='evenodd'
        fill='#FFF'
        d='M.606 40V15.55l.029.22v-.35L5.6 12.93V0h31.598v12.952l4.346 2.718.055-.3V40H.606zM5.6 15.323l-1.77.93 1.77.93v-1.86zM35.8 2.162H7.124v16.042l14.185 7.366L35.8 18.256V2.162zm1.398 13.182v1.87l1.902-.944-1.902-.926zm2.283 3.141l-18.163 8.973-18.595-8.986v19.347h36.758V18.485zm-5.275 14.98H7.837v-2.162h26.369v2.162zm-21.029-21.67l1.952-2.033 3.761 3.769 9-8.038 1.29 2.055-10.312 9.945-5.691-5.698z'
      />
    </svg>
  )
}

export default Join16
