import React from 'react'

const SvgIndexTeamIcon9 = (props) => (
  <svg viewBox='0 0 40 40' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M38.75 0H1.25A1.25 1.25 0 000 1.25v22.5A1.25 1.25 0 001.25 25h2.5a1.25 1.25 0 000-2.5H2.5v-20h35v20h-1.25a1.25 1.25 0 000 2.5h2.5A1.25 1.25 0 0040 23.75V1.25A1.25 1.25 0 0038.75 0zm-22 20a1.82 1.82 0 001-.28l9-5.76a1.74 1.74 0 000-2.92l-9-5.76a1.78 1.78 0 00-1.81-.06A1.73 1.73 0 0015 6.74v11.52a1.73 1.73 0 00.91 1.52 1.79 1.79 0 00.85.22zm.75-11.89l6.84 4.39-6.84 4.39zM10 21.25A3.75 3.75 0 006.25 25v2.5H5A1.25 1.25 0 005 30h30a1.25 1.25 0 000-2.5h-1.25V25a3.75 3.75 0 00-7.5 0v2.5h-2.5V25a3.75 3.75 0 00-7.5 0v2.5h-2.5V25A3.75 3.75 0 0010 21.25zM8.75 27.5V25a1.25 1.25 0 012.5 0v2.5zm20-2.5a1.25 1.25 0 012.5 0v2.5h-2.5zm-10 0a1.25 1.25 0 012.5 0v2.5h-2.5zm20 12.5V35a3.75 3.75 0 00-7.5 0v2.5h-2.5V35a3.75 3.75 0 00-7.5 0v2.5h-2.5V35a3.75 3.75 0 00-7.5 0v2.5h-2.5V35a3.75 3.75 0 00-7.5 0v2.5a1.25 1.25 0 000 2.5h37.5a1.25 1.25 0 000-2.5zm-35 0V35a1.25 1.25 0 012.5 0v2.5zm10 0V35a1.25 1.25 0 012.5 0v2.5zm10 0V35a1.25 1.25 0 012.5 0v2.5zm10 0V35a1.25 1.25 0 012.5 0v2.5z'
        fill='#fff'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgIndexTeamIcon9
