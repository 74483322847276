import React from 'react'

const SvgIndexTeamIcon4 = (props) => (
  <svg viewBox='0 0 41.42 40' {...props}>
    <defs>
      <style>{'.index-team-icon-4_svg__cls-1{fill:#fff}'}</style>
    </defs>
    <g id='index-team-icon-4_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='index-team-icon-4_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='index-team-icon-4_svg__cls-1'
          d='M20.67 21a1.17 1.17 0 01-1.19-1.19v-2.05h-2.91a1.2 1.2 0 110-2.39h2.91v-1h-2.91a1.2 1.2 0 110-2.39h1.86l-2.24-3.32a1.32 1.32 0 01-.15-.9 1.11 1.11 0 01.53-.76 1.24 1.24 0 01.67-.22 1.27 1.27 0 011 .52l2.43 3.7 2.46-3.66a1.28 1.28 0 011-.52 1.3 1.3 0 01.68.22 1.12 1.12 0 01.52.75 1.32 1.32 0 01-.15.9L22.91 12h2a1.2 1.2 0 010 2.39H22v1h2.91a1.2 1.2 0 010 2.39H22v2.09A1.43 1.43 0 0120.67 21z'
        />
        <path
          className='index-team-icon-4_svg__cls-1'
          d='M12.76 40a1.72 1.72 0 110-3.43H19v-6.42a13.24 13.24 0 01-7.69-3.81 13.41 13.41 0 01-3.8-7.83l-.08-.08-6.16-6.64A4.39 4.39 0 010 8.73a4.49 4.49 0 014.48-4.48h2.91V1.72a1.72 1.72 0 113.43 0v15.37a9.85 9.85 0 1019.7 0V3.43h-16a1.69 1.69 0 01-1.72-1.71A1.69 1.69 0 0114.48 0h17.76A1.69 1.69 0 0134 1.72v2.53h2.91a4.56 4.56 0 014.55 4.48 4.18 4.18 0 01-1.35 3.14l-6.19 6.64a13 13 0 01-3.81 7.83 13.19 13.19 0 01-7.68 3.81v6.42h6.27a1.72 1.72 0 010 3.43zM4.55 7.69a1.15 1.15 0 00-1.12 1.12 1.6 1.6 0 00.3.82l.08.07 3.65 4V7.76H4.7c-.07-.07-.07-.07-.15-.07zm29.33 5.82l3.66-3.88.07-.08a1.09 1.09 0 00.3-.82 1.14 1.14 0 00-1.12-1.12h-2.91z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIndexTeamIcon4
