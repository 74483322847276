import React from 'react'

const SvgIndexTeamIcon7 = (props) => (
  <svg viewBox='0 0 38.99 40' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M37.72 8.29h-8.51A5.91 5.91 0 0030 5.36 5.18 5.18 0 0024.67 0a5.5 5.5 0 00-4.09 1.68 7.19 7.19 0 00-1.39 2.06 7.45 7.45 0 00-1.36-2.06A5.47 5.47 0 0013.76 0a5.14 5.14 0 00-4 1.7 5.47 5.47 0 00-1.34 3.66 5.82 5.82 0 00.8 2.93h-8A1.27 1.27 0 000 9.57v11.57a1.27 1.27 0 001.27 1.28H3v16.31A1.27 1.27 0 004.27 40h30.45A1.27 1.27 0 0036 38.73V22.42h1.73A1.27 1.27 0 0039 21.14V9.57a1.26 1.26 0 00-1.27-1.28zM22.39 3.47a3 3 0 012.28-.93 2.63 2.63 0 012.78 2.82 3.16 3.16 0 01-.72 2.05 2.54 2.54 0 01-2.06.88h-4.06a8.48 8.48 0 011.78-4.79zM11 5.36a2.92 2.92 0 01.69-2 2.65 2.65 0 012.1-.85 3 3 0 012.21.94c1.15 1.19 1.59 3.34 1.73 4.84h-4A2.74 2.74 0 0111 5.36zm-8.46 5.48h12.18v9H2.54zm3 11.58h9.17v15H5.55zm11.72 15V10.84h4.46v26.61zm16.18 0h-9.17v-15h9.18zm3-17.58H24.27v-9h12.18z'
        fill='#fff'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgIndexTeamIcon7
