import React from 'react'

const SvgRaysync = (props) => (
  <svg viewBox='0 0 123.08 40' {...props}>
    <defs>
      <style>{'.raysync_svg__cls-1{fill:#fff}'}</style>
    </defs>
    <g id='raysync_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='raysync_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='raysync_svg__cls-1'
          d='M26 0a57 57 0 00-13.25 1.88v2.37a23.15 23.15 0 01-7.46-.18v8.85A31.7 31.7 0 0021 11.74V9.69c1.65-.16 3.33-.25 5-.25a47.62 47.62 0 0113.28 1.84v-9.4A57.7 57.7 0 0026 0zM39.31 34.3a39.55 39.55 0 00-7.46 1.46v2.61a39.79 39.79 0 01-13.28 1.56 38.84 38.84 0 01-13.28-3.24v-9.35a38.06 38.06 0 0015.9 3.46 38.53 38.53 0 009.18-1.11v-2.33a32.24 32.24 0 018.94-2.26zm-5.8-21.24a67.78 67.78 0 00-9.1.84v2.86a47.29 47.29 0 01-7.48.59A47.55 47.55 0 010 14.25v9.37a45.84 45.84 0 0014.17 2.45 49.62 49.62 0 0014.17-1.95V22.7a41.12 41.12 0 015.08-.34 38.5 38.5 0 019.56.93V13.9a55.85 55.85 0 00-9.47-.84zM63.32 9.99h-6.77l.41-2.15h6.77l-.41 2.15zM86.87 5.69H66.04l.41-2.16h20.83l-.41 2.16zM85.96 10.47H65.13l.41-2.15h20.83l-.41 2.15zM83.77 21.96H62.94l.41-2.15h20.83l-.41 2.15zM82.61 28.07H61.78l.41-2.16h20.83l-.41 2.16zM76.78 34.17H60.26l.41-2.15h16.52l-.41 2.15z'
        />
        <path
          className='raysync_svg__cls-1'
          d='M67.3 19.81l-2.73 14.36h-4.31l2.73-14.36h4.31zM84.54 19.81l-2.73 14.36H77.5l2.73-14.36h4.31zM54.33 9.99l1.23-6.46h3.59l-1.23 6.46h-3.59zM69.48 14.01l.55-2.87h2.16l-.55 2.87h-2.16zM68.64 18.47l.54-2.88h2.16l-.55 2.88h-2.15zM78.1 14.01l.55-2.87h2.15l-.54 2.87H78.1zM77.25 18.47l.55-2.88h2.15l-.54 2.88h-2.16zM68.88 34.17l2.73-14.36h4.31l-2.73 14.36h-4.31zM51.84 34.17l4.04-21.18h4.31l-4.04 21.18h-4.31z'
        />
        <path
          className='raysync_svg__cls-1'
          d='M62.59 13.48h-8.98l.41-2.15H63l-.41 2.15zM60.86 22.58h-8.98l.41-2.15h8.98l-.41 2.15zM58.65 34.17h-6.81l.42-2.15h6.8l-.41 2.15zM71.84 18.59l2.74-14.36h4.31l-2.74 14.36h-4.31zM63.23 18.59l1.95-10.27h4.31l-1.96 10.27h-4.3zM80.46 18.59l1.96-10.27h4.3l-1.95 10.27h-4.31zM91.6 8.46l3.14-4.94h5.69l-3.14 4.94H91.6zM99.6 10.87l-5.8 23.3h-4.9l5.81-23.3h4.89zM122.67 8.19h-20.82l.41-2.16h20.82l-.41 2.16z'
        />
        <path
          className='raysync_svg__cls-1'
          d='M97.25 13.74h-7.22l.54-2.87h8.99l-2.31 2.87zM118.09 34.17H89.05l.4-2.15h29.05l-.41 2.15zM122.06 12h-21.05l.41-2.15h21.05l-.41 2.15z'
        />
        <path
          className='raysync_svg__cls-1'
          d='M105.73 31.17L111 3.52h4.3l-5.26 27.65h-4.31zM99.37 20.62l2.05-10.77h3.59l-2.05 10.77h-3.59zM116.83 20.62l2.05-10.77h3.59l-2.05 10.77h-3.59z'
        />
        <path
          className='raysync_svg__cls-1'
          d='M97.36 31.17l4.98-12.58h3.97l-4.98 12.58h-3.97zM114.44 31.17l-.09-12.58h3.97l.09 12.58h-3.97z'
        />
      </g>
    </g>
  </svg>
)

export default SvgRaysync
