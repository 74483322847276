import React from 'react'

const SvgIndexTeamIcon8 = (props) => (
  <svg viewBox='0 0 35.76 39.93' {...props}>
    <defs>
      <style>{'.index-team-icon-8_svg__cls-1{fill:#fff}'}</style>
    </defs>
    <g id='index-team-icon-8_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='index-team-icon-8_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='index-team-icon-8_svg__cls-1'
          d='M33.18 8.63A17.87 17.87 0 106.9 32a1.39 1.39 0 00.87.3 1.43 1.43 0 001.43-1.47 1.39 1.39 0 00-.55-1.12 15 15 0 1123.82-8.25 1.22 1.22 0 000 .34 1.43 1.43 0 002.81.34 17.73 17.73 0 00-2.1-13.51z'
        />
        <path
          className='index-team-icon-8_svg__cls-1'
          d='M26 7.91a1.39 1.39 0 00-.79-.24A1.43 1.43 0 0024 8.3l-5.74 8.61-5.75-8.61a1.43 1.43 0 00-2.61.79 1.38 1.38 0 00.25.8l5.44 8.16h-4.26a1.43 1.43 0 100 2.85h5.5v1.63h-5.5a1.43 1.43 0 100 2.85h5.5v6.74a1.43 1.43 0 102.85 0v-6.74h5.5a1.43 1.43 0 100-2.85h-5.5V20.9h5.5a1.43 1.43 0 100-2.85h-4.26l5.45-8.16a1.45 1.45 0 00.24-.8A1.43 1.43 0 0026 7.91z'
        />
        <path
          className='index-team-icon-8_svg__cls-1'
          d='M33 25.62l-4.5.33a.43.43 0 00-.27.13.09.09 0 000 .05l1 1.17-12.16 9.17-2.63-3.95a1.45 1.45 0 00-1.19-.63 1.39 1.39 0 00-.79.24l-5.19 3.46a1.41 1.41 0 00-.64 1.18 1.43 1.43 0 001.43 1.43 1.47 1.47 0 00.79-.2l4-2.67 2.67 4a1.43 1.43 0 001.18.63 1.47 1.47 0 00.86-.28l13.57-10.19 1 1.12a.06.06 0 00.09 0 .5.5 0 00.18-.27l.9-4.39a.29.29 0 00-.05-.24.29.29 0 00-.23-.1z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIndexTeamIcon8
