import React from 'react'

const JoinUsKonw1 = (props) => (
  <svg viewBox='0 0 74 60' {...props}>
    <path
      fillRule='evenodd'
      fill='#FFF'
      d='M60.89 29.973c7.89 3.203 13.071 10.875 13.115 19.42 0 1.066-.86 1.929-1.921 1.929a1.925 1.925 0 01-1.921-1.929c-.013-8.483-6.189-15.464-14.262-16.798-.02 0-.037.009-.057.009a2.246 2.246 0 01-2.407-2.075 2.249 2.249 0 012.27-2.423c4.523-1.157 7.693-5.243 7.702-9.93a10.267 10.267 0 00-6.532-9.565 20.994 20.994 0 00-2.684-4.492c5.819.407 10.78 4.386 12.462 9.994 1.783 5.986-.562 12.436-5.765 15.86zm-6.506-12.286c-.002 9.757-7.881 17.665-17.598 17.663-11.733.021-21.237 9.569-21.252 21.349a2.406 2.406 0 01-2.399 2.41 2.407 2.407 0 01-2.404-2.41c.057-10.68 6.533-20.269 16.395-24.274a17.67 17.67 0 01-7.946-14.786C19.192 7.888 27.073-.006 36.782.005c9.722 0 17.602 7.913 17.602 17.675v.007zM36.793 4.822c-7.076-.002-12.814 5.756-12.816 12.861-.001 7.105 5.733 12.866 12.809 12.868 7.076.002 12.814-5.756 12.815-12.861.002-7.105-5.732-12.867-12.808-12.868zM10.597 18.176c.009 4.687 3.179 8.773 7.702 9.93a2.249 2.249 0 012.27 2.423 2.246 2.246 0 01-2.407 2.075c-.02 0-.037-.009-.058-.009-8.072 1.334-14.251 8.315-14.261 16.798 0 1.066-.86 1.929-1.921 1.929a1.925 1.925 0 01-1.921-1.929c.043-8.545 5.225-16.217 13.115-19.42-5.203-3.424-7.548-9.874-5.766-15.86 1.683-5.608 6.644-9.587 12.463-9.994a20.994 20.994 0 00-2.684 4.492 10.267 10.267 0 00-6.532 9.565zm26.189 25.277a2.05 2.05 0 01-2.049-2.05 2.05 2.05 0 012.042-2.057h.007a2.05 2.05 0 012.042 2.05 2.05 2.05 0 01-2.042 2.057zm1.702 1.364c1.5 3.141 3.441 7.6 3.441 9.575 0 2.793-5.143 4.739-5.143 4.739s-5.146-1.946-5.146-4.739c0-1.975 1.94-6.431 3.441-9.575 1.073.54 2.334.54 3.407 0zm14.101-8.909c6.478 4.927 10.272 13.07 10.245 21.233 0 1.324-1.07 2.841-2.392 2.841h-.003c-1.322 0-2.392-1.513-2.392-2.837.024-6.664-3.077-13.389-8.365-17.414a2.412 2.412 0 01-.45-3.371 2.389 2.389 0 013.357-.452z'
    />
  </svg>
)

export default JoinUsKonw1
