import React from 'react'

const SvgIndexTeamIcon6 = (props) => (
  <svg viewBox='0 0 34.79 39.93' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M33.13 35.6a1.51 1.51 0 01-1.58-1.5l-5.33 5.24a1.74 1.74 0 01-2.16 0l-3.74-3.74-3.66 3.74a1.59 1.59 0 01-1.08.42 1.51 1.51 0 01-1.58-1.5 1.58 1.58 0 01.41-1.08l4.83-4.82a1.74 1.74 0 012.16 0l3.74 3.74 4.16-4.16a1.49 1.49 0 01-1.5-1.5 1.64 1.64 0 011.58-1.58H33a1.79 1.79 0 011.75 1.5V34a1.76 1.76 0 01-1.66 1.58zm.16-8.4c-.41.17-.91-.17-1.33-.5a16 16 0 00-18.63-4.82A17 17 0 003 38.43c0 .91-2 1.5-2.67 1.5l-.16-.17C0 39.59 0 39.51 0 38.68a20.23 20.23 0 015.26-14.56 19 19 0 017.57-5.07 10.11 10.11 0 01-3.24-3.5 10.49 10.49 0 010-10.39A10.11 10.11 0 0118.4 0a10.24 10.24 0 0110.15 10.4 10.66 10.66 0 01-3.91 8.32 19.46 19.46 0 019.57 6.15c.41.5.5.92.25 1.17a1.65 1.65 0 01-1.17 1.16zm-7.65-16.64a7.3 7.3 0 00-7.15-7.32 7.32 7.32 0 000 14.64 7.23 7.23 0 007.15-7.32z'
        fill='#fff'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgIndexTeamIcon6
