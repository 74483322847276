import PropTypes from 'prop-types'
import styled from 'styled-components'
import React from 'react'

import GlobalStyle from '../../style/theme/global'
import '../../style/theme/font.css'

import Header from './header'
import Footer from './footer'

const LayoutContainer = styled.div`
  height: 100vh;
  position: relative;
  overflow: auto;
`
const Layout = ({ children, hiddenFooter = false }) => {
  return (
    <LayoutContainer>
      <div style={{ position: 'relative' }}>
        <Header />
        {children}
        {!hiddenFooter && <Footer />}
      </div>
      <GlobalStyle />
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hiddenFooter: PropTypes.bool,
}

export default Layout
