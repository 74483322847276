import * as React from 'react'

function Join17(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={45} height={40} {...props}>
      <path
        fillRule='evenodd'
        fill='#FFF'
        d='M44.614 30.263l-8.111 9.266c-.268.298-.648.47-1.045.47-.303 0-.599-.101-.843-.285L28.58 35.83c-.611-1.217-.723-2.087-.261-2.701a1.387 1.387 0 011.947-.262l5.008 3.844 7.257-8.289c.829-.578 1.383-.632 1.958-.125 1.161.506.629 1.388.125 1.966zM25.981 28.41v2.217c0 .769-.622 1.583-1.388 1.583H7.563c-.765 0-1.389-.814-1.389-1.583V28.41c0-.905.309-1.25.796-1.627l5.334-2.568-1.347-1.9c-.166.505-.255-.513-.255-.805v-4.075c0-3.028 2.409-5.075 5.483-5.075s5.483 2.047 5.483 5.075v4.075c0 .28-.083 1.13-.244.786l-1.323 1.931 5.115 2.569c.469.484.765.714.765 1.614zm-2.776.494L17.431 26.9c.259-1.076-.623-1.404-.724-1.791a1.402 1.402 0 01.202-1.138l1.982-2.221v-4.322c0-1.465-1.193-2.616-2.712-2.616-1.519 0-2.712 1.151-2.712 2.616v4.202l2.047 2.323c.237.334.315.757.214 1.156-.101.399-.374.727-.742 1.041l-6.035 2.766v.322h14.254v-.334zM41.451 8.127a.644.644 0 00-.641-.644H27.38c-.675 2.294-2.645 4.064-5.024 4.064-2.374 0-3.656-1.77-4.682-4.064H4.311a.644.644 0 00-.641.644v26.992c0 .352.291.638.641.638H26.71v2.718H4.311a3.357 3.357 0 01-3.346-3.361V8.121c0-1.853 1.5-3.361 3.346-3.361h13.316V1.358C17.627.607 18.232 0 18.98 0h6.759c.747 0 2.171.607 2.171 1.358V4.76h12.9a3.358 3.358 0 013.347 3.361v15.867h-2.706V8.127zM24.392 2.716h-4.059v4.076c0 1.127.914 2.038 2.029 2.038 1.121 0 2.03-.911 2.03-2.038V2.716zm4.011 13.133h8.829c.766 0 1.389.626 1.389 1.395 0 .876-.623 1.394-1.389 1.394h-8.829c-.766 0-1.263-.518-1.263-1.394 0-.769.497-1.395 1.263-1.395zm0 5.899H34.4c.405 0 1.028.626 1.028 1.394 0 .769-.623 1.395-1.028 1.395h-5.997c-.766 0-1.263-.626-1.263-1.395 0-.768.497-1.394 1.263-1.394z'
      />
    </svg>
  )
}

export default Join17
