import * as React from 'react'

function JoinGrowLine(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 648.06 393.76' {...props}>
      <defs>
        <style>
          {
            '.prefix__cls-5{fill:none}.prefix__cls-2{stroke:#fff}.prefix__cls-2,.prefix__cls-6{fill:#02a6bc}.prefix__cls-2,.prefix__cls-5{stroke-width:4px}.prefix__cls-2,.prefix__cls-6,.prefix__cls-7{fill-rule:evenodd}.prefix__cls-3,.prefix__cls-4,.prefix__cls-8{isolation:isolate}.prefix__cls-4{font-size:16px}.prefix__cls-4,.prefix__cls-7,.prefix__cls-8{fill:#fff}.prefix__cls-4,.prefix__cls-8{font-family:Source Han Sans CN, Source Han Sans SC,SourceHanSansCN-Bold-GBpc-EUC-H,Source Han Sans CN}.prefix__cls-5{stroke:#f2c410;stroke-linecap:round}.prefix__cls-8{font-size:20px}'
          }
        </style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            strokeMiterlimit={10}
            strokeWidth={2}
            stroke='#fff'
            fill='none'
            d='M173.09 314l68.63-72.8L247 206l56-28.31 42-35.54 42-16.82L421.56 84 459 67l53-60.02L485.86 80l-33.65 31.67L427 144l-51.56 18L355 200.14 293 233l-35 40-78.91 41-38.02 39.39L90 371'
          />
          <path
            className='prefix__cls-2'
            d='M514 2a10 10 0 11-10 10 10 10 0 0110-10zM422 74a10 10 0 11-10 10 10 10 0 0110-10zM248 198a10 10 0 11-10 10 10 10 0 0110-10zM293 223a10 10 0 11-10 10 10 10 0 0110-10zM485.62 70a10 10 0 11-10 10 10 10 0 0110-10zM90 361a10 10 0 11-10 10 10 10 0 0110-10z'
          />
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(0 377.03)'>
              {'\u57FA\u5C42\u5458\u5DE5'}
            </text>
          </g>
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(158 214.03)'>
              {'\u57FA\u5C42\u7BA1\u7406'}
            </text>
          </g>
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(313.5 242)'>
              {'\u6838\u5FC3\u9AA8\u5E72'}
            </text>
          </g>
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(401.46 178.14)'>
              {'\u8D44\u6DF1\u4E13\u5BB6'}
            </text>
          </g>
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(502 91.91)'>
              {'\u4E1A\u5185\u6743\u5A01'}
            </text>
          </g>
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(256 150.03)'>
              {'\u4E2D\u5C42\u7BA1\u7406'}
            </text>
          </g>
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(332 91.91)'>
              {'\u9AD8\u7EA7\u7BA1\u7406'}
            </text>
          </g>
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(446 20.62)'>
              {'\u5408\u4F19\u4EBA'}
            </text>
          </g>
          <path className='prefix__cls-5' d='M189 314h124M353 144h181' />
          <path className='prefix__cls-6' d='M337.5 289.5a24 24 0 11-24 24 24 24 0 0124-24z' />
          <path
            className='prefix__cls-7'
            d='M354.23 322.32a1.3 1.3 0 01-1.24-.81v-1.77c0-4.1-2.25-7.1-5.5-8-.2-.06-.92-.4-.95-.56v-1s0 .25.06-.22a.81.81 0 01.22-.2 1.61 1.61 0 01.45-.27 3.62 3.62 0 002.17-3.38 3.74 3.74 0 00-2.76-3.65 1.29 1.29 0 01-1-1.56 1.26 1.26 0 011.51-1 6.26 6.26 0 014.68 6c0 1.54-.55 3.81-1.66 4.22 3.22 1.72 5.24 5.14 5.23 9.53v1.88a1.49 1.49 0 01-1.21.79zm-11.37-8.82c4.55 1.34 7.84 6 7.84 11.17v.93h-.13a.14.14 0 010 .06 1.13 1.13 0 01-1.25 1.26 1.25 1.25 0 01-1.24-1.27h.71v-.93a10.68 10.68 0 00-10.5-10 9.9 9.9 0 00-9.76 10v.99a1.14 1.14 0 01-1.24 1.26c-.69 0-.54-.57-.54-1.27s.08 0 .09 0h-.09v-.93c-.29-5.14 2.33-9.77 7-11.17a9.27 9.27 0 01-2.9-6.4v.21a7.44 7.44 0 1114.86-.21 9.3 9.3 0 01-2.85 6.3zm-4.4-11.11a4.7 4.7 0 00-4.95 4.71 5 5 0 004.95 5.05 5 5 0 004.93-5.06 4.71 4.71 0 00-4.93-4.7zm-8.48.1a3.73 3.73 0 00-2.76 3.65c0 1.41 1.68 2.82 2.17 3.38a1.28 1.28 0 01.45.27 3.72 3.72 0 00.78.2c.37.47-.5.22-.5.22v1c.68.16-.77.5-.94.56-3.25.88-5.51 3.88-5.51 8v1.86a1.43 1.43 0 01-1.24.72 1.3 1.3 0 01-1.24-.81v-1.77c0-4.39 2-7.81 5.22-9.53-1.1-.23-1.65-2.66-1.65-4.21a6.24 6.24 0 014.67-6.05 1.26 1.26 0 011.52 1 1.3 1.3 0 01-.95 1.51z'
          />
          <path className='prefix__cls-6' d='M553.62 120a24 24 0 11-24 24 24 24 0 0124-24z' />
          <path
            className='prefix__cls-7'
            d='M567.33 158.87c-.07.4-.79.53-.4.53h-25.07a1.66 1.66 0 01-1.18-.53 2.3 2.3 0 01-.39-1.33l1.44-10.78a1.43 1.43 0 011.44-1.33l7.18-.8v2.8l-6 1.2-1 8h21.55l-1.31-8-5.88-1.2v-2.8l7.19.8c.78.13 2.11 1.23 1.43 1.33l1.44 10.78c-.05.9-.18 1.07-.44 1.33zM554 145.17c.55 0 1 .39 1.18 1.49l2.64 6.22c-.55.4 0 .94-.94 1.34l-2 2.27c-.24-.15-.57 0-.92 0s-.68-.14-.7 0l-1.59-2c-.84-.84-1-1.37-.84-1.77l2-6.09c.17-1.07.62-1.45 1.17-1.46zm-.13 8l.53-.4-.53-1.86-.52 1.73zm0-8a8 8 0 01-8-8v-.25a8 8 0 118 8.23zm0-13.45c-2.87 0-5.22 3.17-5.22 5.45a5.24 5.24 0 005.22 5.07c2.88 0 5.55-2.4 5.55-5.07 0-2.28-2.67-5.45-5.55-5.45z'
          />
          <g className='prefix__cls-3'>
            <text className='prefix__cls-8' transform='translate(373 321.24)'>
              {'\u4E09\u677F\u65A7'}
            </text>
          </g>
          <g className='prefix__cls-3'>
            <text className='prefix__cls-8' transform='translate(588.06 152.43)'>
              {'\u4E09\u628A\u67AA'}
            </text>
          </g>
          <path className='prefix__cls-2' d='M176 303a10 10 0 11-10 10 10 10 0 0110-10z' />
          <g className='prefix__cls-3'>
            <text className='prefix__cls-4' transform='translate(90 316.92)'>
              {'\u4F18\u79C0\u4EBA\u624D'}
            </text>
          </g>
          <path
            className='prefix__cls-2'
            d='M432 134a10 10 0 11-10 10 10 10 0 0110-10zM345 134a10 10 0 11-10 10 10 10 0 0110-10z'
          />
        </g>
      </g>
    </svg>
  )
}

export default JoinGrowLine
