import React from 'react'

const SvgAboutQualificationIconm9 = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130 105' {...props}>
    <path
      fillRule='evenodd'
      opacity={0.502}
      fill='#2BBFA5'
      d='M112.23 79.799c-.897.354 1.453-.238.482-.068 2.683-2.926 5.462-11.35 5.399-13.57-.577-20.458-13.222-31.27-25.797-33.04-.877-.124-.95.008-1.2 0 .004-.205.055-.392 0-.59C84.269 7.714 65.785.304 53.853 4.242c5.685-2.755 9.212-4.239 16.258-2.806C82.546 3.967 93.282 16.542 96.754 31.5c1.754-.211 3.61-.122 5.537.316 11.237 2.553 20.533 15.712 20.533 29.34 0 9.272-4.304 16.16-10.594 18.643z'
    />
    <path
      fillRule='evenodd'
      opacity={0.6}
      fill='#2BBFA5'
      d='M104.941 84.761c-.896.359.853-.242-.118-.07 2.684-2.969 3.599-10.52 3.599-13.772 0-15.377-13.018-30.09-26.989-29.94-.886.009-.949.009-1.2 0 .004-.207.038-.394 0-.599-2.849-15.507-18.28-31.661-34.186-29.94-.422.046-.846.016-1.266.032C49.465 5.88 55.796 4.195 62.84 5.65c12.432 2.568 23.159 14.91 26.63 30.09 1.753-.213 3.609-.123 5.536.322 11.234 2.59 20.527 15.947 20.527 29.778 0 9.41-4.302 16.401-10.592 18.921z'
    />
    <path
      fillRule='evenodd'
      opacity={0.2}
      fill='#2BBFA5'
      d='M64.926 53.493c31.354 0 60.866 9.129 64.551 22.75 3.881 14.345-24.169 28.112-64.551 28.112-40.382 0-68.432-13.767-64.551-28.112 3.685-13.621 33.198-22.75 64.551-22.75z'
    />
    <path
      fillRule='evenodd'
      fill='#2BBFA5'
      d='M85.393 92.404l-.015.068-1.522-.406h-.001l-.002-.001-62.632-16.702-.005-.001a.017.017 0 01-.005-.002l-.532-.142-.003-.017C12.392 72.64 5.861 62.533 5.861 52.258c0-10.499 6.816-17.459 15.355-15.517.583.133 1.158.307 1.725.513 1.197-16.649 12.815-27.61 27.346-24.746 12.971 2.556 24.17 15.262 27.792 30.374 1.829-.212 3.765-.122 5.776.32 11.721 2.58 21.417 15.875 21.417 29.644 0 13.161-8.86 21.57-19.879 19.558zm-20.37-55.568l-13.42-13.477c-1.913-2.329-2.934-1.674-2.934 1.712 0 .844.033 2.892 0 3.477-11.003-2.406-21.267 4.057-21.483 20.225-.005 1.116.966.845 1.387-.622 2.726-10.761 9.583-11.575 17.178-9.836l2.918 2.405.002 2.111-.002.088c-.101 4.217 1.019 4.621 2.934 3.15l13.42-7.462s.893-.275 0-1.771zm4.572 9.787c-2.939 11.128-10.191 12.388-17.99 10.469l-2.936-2.459c0-1.539-.003-2.283-.005-2.613l.005-.147c.101-4.212-1.018-4.616-2.918-3.155 0 0-11.871 6.112-12.925 7.177-1.052 1.064 0 1.713 0 1.713l12.925 13.65c1.898 2.414 2.918 2.208 2.918-1.172 0-3.837-.033-3.235 0-3.281 11.246 2.883 22.196-3.982 22.433-20.828.004-1.166-1.052-.882-1.507.646zM48.664 52.02c-.009.201-.004-.621 0 0zm.007-9.189c.013-.399.004 1.273 0 0z'
    />
  </svg>
)

export default SvgAboutQualificationIconm9
