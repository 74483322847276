import React from 'react'
import styled from 'styled-components'

const MenuIconContainer = styled.div`
  width: 18px;
  height: 14px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
`

const MenuIconLine = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: ${(p) => p.color || 'white'};
`

export default React.memo(function MenuIcon({ color = 'white', ...otherProps }) {
  return (
    <MenuIconContainer {...otherProps}>
      {Array.from({ length: 3 }).map((_, index) => (
        <MenuIconLine color={color} key={index} />
      ))}
    </MenuIconContainer>
  )
})
