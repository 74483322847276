import React from 'react'

const JoinUsKonw4 = (props) => (
  <svg viewBox='0 0 64 60' {...props}>
    <path
      fillRule='evenodd'
      fill='#02A6BC'
      d='M42.215 32.781h9.963a2.246 2.246 0 012.24 2.254 2.246 2.246 0 01-2.24 2.254h-9.963a2.247 2.247 0 01-2.24-2.254 2.247 2.247 0 012.24-2.254zm0-13.524h9.963a2.246 2.246 0 012.24 2.254 2.246 2.246 0 01-2.24 2.254h-9.963a2.247 2.247 0 01-2.24-2.254 2.247 2.247 0 012.24-2.254zm17.969 33.044l-17.648 4.643a2.241 2.241 0 01-2.835-1.668 2.253 2.253 0 011.699-2.689l17.649-4.644a.173.173 0 00.097-.173V4.681a.181.181 0 00-.068-.151l-24.481 6.417a.173.173 0 00-.097.173v48.619L4.335 51.783a4.662 4.662 0 01-3.443-4.509V5.733a4.675 4.675 0 011.807-3.757 4.46 4.46 0 013.929-.811l16.744 4.402a2.256 2.256 0 011.539 2.648 2.241 2.241 0 01-2.674 1.71L5.5 5.523c-.127.052-.127.105-.127.172v41.549a.175.175 0 00.097.166l24.549 6.491V11.097a4.662 4.662 0 013.443-4.508L57.891.158A4.46 4.46 0 0161.82.97a4.678 4.678 0 011.807 3.726v43.096a4.671 4.671 0 01-3.443 4.509zM23.297 23.765h-9.956a2.247 2.247 0 01-2.24-2.254 2.247 2.247 0 012.24-2.254h9.956a2.247 2.247 0 012.241 2.254 2.247 2.247 0 01-2.241 2.254zm0 13.524h-9.956a2.247 2.247 0 01-2.24-2.254 2.247 2.247 0 012.24-2.254h9.956a2.247 2.247 0 012.241 2.254 2.247 2.247 0 01-2.241 2.254z'
    />
  </svg>
)

export default JoinUsKonw4
