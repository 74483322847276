import { Link } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, fontSize, Media, spacing, transition } from '../../style/theme'

export const LangSpan = styled.span`
  cursor: pointer;
  width: 80px;
  ${Media.phone`
    width: 62px;
  `}
`
export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: ${spacing.base} 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  z-index: 10;
  transition: ${transition('background-color')};
  ${Media.phone`
    padding: ${spacing.small};
    font-size: ${(p) => (p.isRecruitmentPage ? fontSize.tiny : fontSize.small)};
  `}
`

export const LogoImageLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  svg {
    height: 25px;
  }
  ${Media.phone`
    margin-right: 30px;
    svg {
      width: 100%;
      height: auto;
    }
  `}
`

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  flex-shrink: 0;
`

export const SplitLine = styled.div`
  width: 1px;
  height: 14px;
  margin: auto 36px;
  background-color: white;
  ${Media.phone`
    margin: auto ${spacing.base};
  `}
`

export const MoreTextContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  span {
    margin-left: 8px;
  }
  width: ${(p) => (p.isRecruitmentPage ? '211px' : '')};
  ${Media.phone`
    width: auto;
  `}
`

export const SideBarContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 240px;
  padding-top: 100px;
  background: rgb(12 21 29 / 60%);
  transition: ${transition('transform')};
  transform: translateX(${(p) => (p.show ? '0' : '240')}px);
  cursor: default;
  z-index: -1;
  ${Media.phone`
    bottom: auto;
    width: 100px;
    height: 240px;
    padding-top: ${spacing.plus};
  `}
`

export const SideBarLink = styled(Link)`
  display: block;
  padding: ${spacing.small} 25px;
  margin: ${spacing.small} 0;
  transition: ${transition('background-color')};
  :hover {
    background-color: ${color.panelLight};
  }
  ${Media.phone`
    padding: 5px 15px;
  `}
`

export const RecruitmentListContainer = styled.div`
  margin-right: ${spacing.large};
  a {
    margin-right: ${spacing.large};
  }
  .actived {
    color: ${color.primary};
  }
  ${Media.phone`
    margin-right: 0;
    a {
      margin-right: ${spacing.small};
    }
  `}
`
