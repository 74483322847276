import React from 'react'

const JoinUsOffice = (props) => (
  <svg {...props} viewBox='0 0 57 61'>
    <path
      fillRule='evenodd'
      fill='#FFF'
      d='M54.628 55.827c-.128 2.396-2.125 4.297-3.758 4.297H6.915a4.533 4.533 0 01-4.537-4.297L.5 12.884C.118 10.421 1.674 7.877 4.102 7.92c.038-.08.076-.24.123-.32l6.13-1.914c.238-.053.486-.042 1.385.031l1.707-4.79c.262-.519.802.033 1.376-.794.015 0 .03.008.046.008h26.923c.07-.001.14.002.308.008.47-.012.014-.019.071-.023.574.724 1.115.276 1.368.801l2.134 4.339c.229-.054.469-.048.696.015L52.815 7.8v.04c2.304-.116 5.125 2.664 4.182 5.036l-2.369 42.951zM4.898 9.891c-1.276.448-2.111 1.854-2.056 2.859l2.375 42.937-.003-.005c.043.864.986 1.548 1.686 1.54h17.91v-8.325a1.56 1.56 0 01.046-.385 1.35 1.35 0 01.062-.55l.905-2.686L9.535 27.704c-.041-.046-.083-.092-.123-.139-1.809-2.106-1.594-5.304.479-7.141l.696-.613-2.93-.974a2.395 2.395 0 01-.323-.135c-1.189.268-1.671-2.073-1.076-3.281l3.247-6.566-4.607 1.036zm9.577-4.594L9.52 16.245l2.721 1.06c1.379.277 1.435.978 1.608 1.838.341 1.157-.124 1.746-.089 2.325l-1.983 1.154-.054.05a2.185 2.185 0 00-.1 3.053l15.254 16.461.097-.267c0-.008-.007-.016-.007-.024L14.475 5.297zm2.257-1.867l11.757 34.071L40.247 3.43H16.732zm25.771 1.891L29.819 42.476l15.483-16.751a2.181 2.181 0 00.572-1.587c-.027-.585-.29-.638-.727-1.516l-1.302-1.158a2.446 2.446 0 01-.7-1.076c-.415-1.283.271-2.665 1.535-3.087l3.188-1.06-5.365-10.92zm11.615 7.41c.054-1.041-.378-2.564-1.578-2.847l-5.263-1.327 3.382 6.872c.052.106.097.216.134.33.415 1.651-.272 2.664-1.533 3.086l-2.938.974.938.62c.43.039-.148.078-.106.119 2.01 1.909 2.115 5.112.235 7.154L27.67 49.74v2.221c.91-.558 1.024-.591 1.747-.591 1.182 0 2.141.74 2.141 1.842 0 1.202-.959 2.176-2.141 2.176-.723 0-.837-.178-1.747-.924v2.77h23.04c.258 0 .993-.684 1.039-1.539l2.373-42.96-.004-.004zM28.821 53.212c0 .334 1.059.605.596.605.328 0 .703-.271.703-.605.78-.332-.376-.601-.703-.604.463 0-.596.271-.596.604z'
    />
  </svg>
)

export default JoinUsOffice
