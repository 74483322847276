import React from 'react'

const SvgAboutQualificationIconm1 = (props) => (
  <svg viewBox='0 0 47 41' {...props}>
    <path
      fillRule='evenodd'
      fill='#2DBEC3'
      d='M46.079 15.1c-2.884 11.959-16.978 16.931-16.978 16.931l.712 5.106c-2.136 1.748-5.462 2.554-5.462 2.554L24.318.031l.39.017 4.274 29.295s14.887-8.918 12.11-18.141c-2.776-9.223-14.84-7.256-14.84-7.256 6.41-2.151 22.712-.807 19.827 11.154zM16.822 39.766L22.215.654l.705.001-.127 39.111s-3.557.575-5.971 0zm.616-17.303l-3.091-.541-2.309 2.681.112-3.661-2.397-.709 3.118-1.363.844-3.335 1.437 2.557 3.157-.867-2.053 2.556 1.182 2.682zm-2.035-11.64l-.901-.254-.844.429.236-.73-.682-.255 1.014-.127.439-.629.291.581.999-.025-.759.415.207.595zm-6.349 6.185l-1.301-.276-1.009 1.098.092-1.548-1.006-.337 1.336-.528.398-1.398.576 1.104 1.347-.317-.9 1.049.467 1.153zm-4.093 9.12l.093 2.019-1.784-1.275-2.066.956.939-2.232-1.315-1.169 2.254.106 1.315-1.807.282 2.019 2.16.427-1.878.956zm6.33 4.962l1.663-2.284.355 2.553 2.732.538-2.375 1.208.118 2.554-2.255-1.612-2.613 1.21 1.188-2.822-1.662-1.479 2.849.134z'
    />
  </svg>
)

export default SvgAboutQualificationIconm1
