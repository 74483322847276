import React from 'react'

const JoinUsKonw5 = (props) => (
  <svg viewBox='0 0 57 62' {...props}>
    <path
      fillRule='evenodd'
      fill='#02A6BC'
      d='M53.892 55.675c-1.004 0-2.02-.855-2.02-2.49v-2.627l-10.001 9.838c-.764.751-1.573.79-1.728.79-1.109 0-1.766-.343-1.959-1.02l-8.225-8.09-8.457 8.32c-.858.844-2.596.845-3.455 0-.43-.423-.676-1.042-.676-1.7 0-.658.246-1.279.676-1.7l10.185-10.019c.134-.132.847-.79 1.727-.79.624 0 .989.316 1.18.501h.253l8.751 8.609 8.273-8.138h-2.672c-1.56 0-2.53-.955-2.53-2.49 0-1.018.902-2.49 2.53-2.49h8.148c.633 0 .999.326 1.187.509.875.082 1.344.802 1.344 1.48v9.017c0 1.536-.97 2.49-2.531 2.49zm-9.166-17.034c-1.054 0-1.699-.309-1.928-.92-4.049-2.88-9.389-4.59-14.367-4.59-12.925 0-23.44 10.12-23.44 22.559 0 .95 0 1.771.404 2.564l.106.208v.234c0 1.047-1.467 2.49-2.531 2.49-1.561 0-2.53-.954-2.53-2.49v-6.011c0-.517 0-1.153.509-2.23v-.275c0-.484.2-.878.36-1.194a2.06 2.06 0 00.15-.334l-.002-1.386.294-.289c.207-.203.216-.345.216-.805v-.409l.294-.29c.091-.089.162-.155.216-.211v-.501l.293-.289c.14-.138.213-.28.322-.496.11-.215.24-.471.455-.739.07-.294.198-.546.308-.763.055-.109.139-.274.151-.334v-.987l.507.012v-.412l1.313-1.29c.091-.094.201-.261.217-.332v-.986l.76.02c.12-.189.282-.443.55-.707l2.145-2.095c2.034-1.501 4.121-3.041 6.705-4.566l.238-.141h.787c.037-.009.205-.092.316-.146.21-.103.454-.223.735-.293l.442-.436c-4.418-3.018-7.079-7.877-7.079-13.166 0-4.629 2.052-9.099 5.627-12.266C20.881 1.149 25.735-.4 30.573.103c7.559 1.061 13.562 6.968 14.638 14.368.465 6.38-2.165 11.856-7.269 15.377 3.435 1.182 6.066 2.698 8.002 4.605.808.794 1.068 1.933.661 2.901-.334.795-1.054 1.287-1.879 1.287zm-4.552-23.859c-.937-5.071-4.726-8.798-9.885-9.722-3.793-.656-7.556.52-10.315 3.235-2.758 2.714-3.955 6.413-3.285 10.149.938 5.075 4.726 8.801 9.884 9.724 3.795.659 7.556-.52 10.315-3.234 2.759-2.714 3.956-6.414 3.286-10.152z'
    />
  </svg>
)

export default JoinUsKonw5
