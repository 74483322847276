import * as React from 'react'

function CloseIcon(props) {
  return (
    <svg viewBox='0 0 1025 1024' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M16.944 16.887C3.128 31.215.569 37.355.569 58.336V83.41l213.385 214.408 213.386 214.41L227.77 712.82C117.753 822.839 21.55 921.088 14.386 930.299c-20.468 25.586-18.933 53.218 3.582 75.734 22.516 22.515 50.149 24.05 75.734 3.582 9.211-7.164 107.46-103.367 217.48-213.386l201.104-200.08 201.104 200.08c110.019 110.02 208.268 206.222 217.48 213.386 25.585 20.469 53.217 18.933 75.733-3.582 22.515-22.516 24.05-50.148 3.582-75.734-7.164-9.21-103.367-107.46-213.385-217.99L596.718 511.715l200.08-201.105c110.02-110.018 206.222-208.268 213.386-217.479 22.004-27.12 16.887-63.964-11.77-86.48C994.322 3.07 979.482 0 965.665 0H940.08L726.182 213.385 512.285 426.771 298.388 213.385 84.49 0H58.905C36.389 0 31.272 2.047 16.944 16.887z' />
    </svg>
  )
}

export default CloseIcon
