import React from 'react'

const SvgIndexTeamIcon1 = (props) => (
  <svg viewBox='0 0 44.05 40' {...props}>
    <defs>
      <style>{'.index-team-icon-2_svg__cls-1{fill:#fff}'}</style>
    </defs>
    <g id='index-team-icon-2_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='index-team-icon-2_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='index-team-icon-2_svg__cls-1'
          d='M13.23 0v7.82h15.64V0zm2.2 2.2h11.24v3.42H15.43zm28.62 35.89L43 40l-6.09-3.48c-.29-.16-.58-.31-.88-.44a9.3 9.3 0 00-7.88.22l-.41.22a11.42 11.42 0 01-11.35 0l-.38-.2a9.26 9.26 0 00-8.43 0l-.36.19L1.09 40 0 38.09l5.85-3.34.24-.14a11.11 11.11 0 011.75-.81 11.46 11.46 0 018.62.32c.34.15.66.31 1 .49a9.23 9.23 0 009.17 0c.33-.18.66-.35 1-.51a11.51 11.51 0 014.67-1 11.89 11.89 0 012.59.29 11.42 11.42 0 012.11.72c.33.15.65.31 1 .49z'
        />
        <path
          className='index-team-icon-2_svg__cls-1'
          d='M7.4 5.64v17.59H11l5.1-2.2H9.6V7.84h23V21h-6.15l5.09 2.2h3.23V5.64z'
        />
        <path
          className='index-team-icon-2_svg__cls-1'
          d='M21.26 16.39L10.52 21h5.55l5.19-2.24L26.45 21H32zm0 0L10.52 21h5.55l5.19-2.24L26.45 21H32zm13.51 5.83L32 21l-10.74-4.61L10.52 21 7.4 22.37 1 25.15l4.88 9.6.81 1.58h.88a9.26 9.26 0 018.43 0h12.14a9.3 9.3 0 017.86-.25l1-2 4.56-9zm.11 11.18l-.35.69h-6.91c-.35.16-.68.33-1 .51a9.23 9.23 0 01-9.17 0c-.32-.18-.64-.34-1-.49H8l-.17-.33L4 26.24l7-3 5.1-2.2 5.19-2.24 5.16 2.2 5.09 2.2 7 3zm-13.62-17L10.52 21h5.55l5.19-2.24L26.45 21H32z'
        />
        <path
          className='index-team-icon-2_svg__cls-1'
          d='M20.25 17.23h2.2V28h-2.2zm-1.16-6h9.78v2.2h-9.78zm-5.94 0h2.28v2.2h-2.28z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIndexTeamIcon1
