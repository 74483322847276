import * as React from 'react'

function GoIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 149.41 29.41' {...props}>
      <g data-name='\u56FE\u5C42 2'>
        <g
          data-name='\u56FE\u5C42 1'
          fill='none'
          stroke='#fff'
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d='M0 14.71h148M134 .71l14 14-14 14' />
        </g>
      </g>
    </svg>
  )
}

export default GoIcon
