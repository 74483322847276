import React from 'react'

const JoinUsKonw2 = (props) => (
  <svg viewBox='0 0 61 60' {...props}>
    <path
      fillRule='evenodd'
      fill='#02A6BC'
      d='M60.537 30.565a1.936 1.936 0 01-1.865 1.459h-2.058c-.195 2.827-.873 5.566-1.962 8.185a25.791 25.791 0 01-5.549 8.26 25.59 25.59 0 01-8.235 5.566 26.232 26.232 0 01-7.944 1.945v1.467c0 .92-.455 1.721-1.111 2.124a1.87 1.87 0 01-1.029.307 1.935 1.935 0 01-1.873-1.511 2.822 2.822 0 01-.156-.913v-1.496c-2.782-.195-5.476-.876-8.049-1.953a25.705 25.705 0 01-8.235-5.567 25.673 25.673 0 01-5.549-8.259 26.281 26.281 0 01-1.954-8.155H3.319c-.141 0-.283-.015-.417-.038H2.85a1.937 1.937 0 01-1.925-1.93c0-.479.179-.92.47-1.257a2.345 2.345 0 011.917-.965h1.671c.209-2.731.88-5.365 1.931-7.894a26.154 26.154 0 015.55-8.26 25.87 25.87 0 018.25-5.588 26.076 26.076 0 018.033-1.953V2.388c0-1.317.94-2.393 2.088-2.393 1.149 0 2.089 1.076 2.089 2.393v1.774c2.76.201 5.43.875 7.981 1.944a25.71 25.71 0 018.235 5.567 25.678 25.678 0 015.549 8.26 26.432 26.432 0 011.932 7.893h1.693c1.283 0 2.328.943 2.328 2.095 0 .225-.038.434-.105.644zm-12.576-2.739h4.498C51.437 17.614 43.24 9.436 32.924 8.418v3.989c0 1.316-.94 2.393-2.089 2.393-1.148 0-2.088-1.077-2.088-2.393V8.418c-10.383.935-18.669 9.136-19.699 19.408h4.438c1.253 0 2.275.943 2.275 2.095 0 1.153-1.022 2.095-2.275 2.095H9.033c.955 10.37 9.286 18.637 19.714 19.595v-4.182c0-1.317.94-2.395 2.088-2.395 1.149 0 2.089 1.078 2.089 2.395v4.167c10.36-1.025 18.602-9.247 19.55-19.58h-4.513c-1.283 0-2.327-.942-2.327-2.095 0-1.152 1.052-2.095 2.327-2.095zm-17.208 9.996c-4.303 0-7.794-3.501-7.794-7.818 0-4.318 3.491-7.819 7.794-7.819 4.289 0 7.78 3.501 7.795 7.819 0 4.317-3.491 7.818-7.795 7.818zm0-11.283a3.466 3.466 0 00-3.453 3.465 3.46 3.46 0 003.453 3.464 3.46 3.46 0 003.454-3.464 3.462 3.462 0 00-3.454-3.465z'
    />
  </svg>
)

export default JoinUsKonw2
