import React from 'react'

const SvgAboutQualificationIcon10 = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 103 111' {...props}>
    <path
      fillRule='evenodd'
      opacity='0.4'
      fill='rgb(43, 191, 165)'
      d='M51.600,66.998 C27.598,66.998 4.222,74.407 0.459,86.35 C-3.594,98.565 18.543,110.996 51.362,110.996 C84.188,110.996 106.451,98.561 102.534,86.35 C98.898,74.408 75.605,66.998 51.600,66.998 ZM36.139,101.203 L46.846,89.478 L25.256,89.478 L60.509,73.278 L56.418,82.733 L76.657,82.733 L36.139,101.203 Z'
    />
    <path
      fillRule='evenodd'
      opacity='0.502'
      fill='rgb(43, 191, 165)'
      d='M81.61,76.266 L73.472,76.266 L70.175,37.394 L71.440,36.658 C72.31,36.314 72.521,35.815 72.859,35.213 C73.904,33.351 73.268,30.979 71.440,29.915 L69.170,28.594 L68.589,23.498 L59.498,22.963 L56.248,21.72 C55.672,20.737 55.20,20.561 54.357,20.561 C52.963,20.561 51.757,21.331 51.92,22.468 L30.357,21.424 L30.357,6.72 C30.357,2.855 32.917,0.247 36.76,0.247 L61.302,0.247 C62.819,0.247 64.274,0.861 65.347,1.954 L85.59,20.958 C86.132,22.50 86.735,23.532 86.735,25.78 L86.735,70.487 C86.735,73.678 84.195,76.266 81.61,76.266 Z'
    />
    <path
      fillRule='evenodd'
      opacity='0.6'
      fill='rgb(43, 191, 165)'
      d='M77.0,83.91 L71.519,83.91 L73.519,36.311 L23.561,15.311 L23.561,11.127 C23.561,7.815 26.215,5.130 29.488,5.130 L57.668,5.130 C59.241,5.130 60.749,5.762 61.860,6.887 L81.144,26.362 C82.256,27.486 82.881,29.12 82.881,30.603 L82.881,77.141 C82.881,80.427 80.248,83.91 77.0,83.91 Z'
    />
    <path
      fillRule='evenodd'
      fill='rgb(43, 191, 165)'
      d='M71.552,89.995 L24.384,89.995 C20.289,89.995 16.970,86.649 16.970,82.522 L16.970,17.469 C16.970,13.342 20.289,9.996 24.384,9.996 L52.57,9.996 C54.24,9.996 55.909,10.784 57.300,12.185 L76.794,31.833 C78.185,33.234 78.966,35.135 78.966,37.117 L78.966,82.522 C78.966,86.649 75.647,89.995 71.552,89.995 ZM30.18,77.976 L50.760,77.976 C51.915,77.976 52.852,77.32 52.852,75.867 C52.852,74.702 51.915,73.758 50.760,73.758 L30.18,73.758 C28.863,73.758 27.926,74.702 27.926,75.867 C27.926,77.32 28.863,77.976 30.18,77.976 ZM30.18,67.397 L50.760,67.397 C51.915,67.397 52.852,66.453 52.852,65.288 C52.852,64.124 51.915,63.180 50.760,63.180 L30.18,63.180 C28.863,63.180 27.926,64.124 27.926,65.288 C27.926,66.453 28.863,67.397 30.18,67.397 ZM45.724,30.917 L33.119,23.657 C32.641,23.382 32.101,23.237 31.550,23.237 C29.803,23.237 28.387,24.665 28.387,26.426 L28.387,40.944 C28.387,41.497 28.530,42.41 28.802,42.522 C29.668,44.51 31.600,44.584 33.118,43.712 L45.724,36.453 C46.215,36.171 46.621,35.761 46.901,35.267 C47.768,33.738 47.241,31.790 45.724,30.917 ZM68.196,52.601 L30.18,52.601 C28.863,52.601 27.926,53.545 27.926,54.710 C27.926,55.874 28.863,56.819 30.18,56.819 L68.196,56.819 C69.352,56.819 70.289,55.874 70.289,54.710 C70.289,53.545 69.352,52.601 68.196,52.601 ZM71.51,65.491 C70.235,64.667 68.909,64.667 68.93,65.491 L61.202,72.435 L58.284,69.493 C57.467,68.669 56.141,68.669 55.325,69.493 C54.508,70.316 54.508,71.652 55.325,72.475 L59.723,76.908 C60.131,77.320 60.667,77.526 61.202,77.526 C61.738,77.526 62.274,77.320 62.682,76.908 L71.51,68.473 C71.869,67.650 71.869,66.314 71.51,65.491 ZM33.132,29.173 L40.966,33.684 L33.132,38.196 L33.132,29.173 Z'
    />
  </svg>
)

export default SvgAboutQualificationIcon10
