import React from 'react'

const SvgAboutQualificationIconm2 = (props) => (
  <svg viewBox='0 0 28 41' {...props}>
    <path
      fillRule='evenodd'
      fill='#2DBEC3'
      d='M27.32 22.552c-1.72 5.741-6.404 10.044-6.404 10.044s-3.408 2.237-3.481 4.98c-.072 2.745.933 3.415.933 3.415h-5.67l3.374-19.744s2.263-.842 3.778-2.363c0 0 5.221-4.98 7.206-8.037 0 0 1.985 5.972.264 11.705zm-12.425-2.064L11.58 40.946H6.075l5.485-18.162c.02-.127-.02-.336-.073-.566-1.793.797-3.03 1.2-3.03 1.2l1.27-6.643C23.072 11.756 24.085.975 24.085.975c4.307 9.641-3.163 16.098-9.19 19.513zm-10.501 6.18c-.747 1.686 0 4.325 3.368 4.325l-1.105 4.786s-5.73-.805-5.975-7.597c-.225-6.785 7.755-10.878 7.755-10.878l-1.045 6.412s-2.191 1.163-2.998 2.952z'
    />
  </svg>
)

export default SvgAboutQualificationIconm2
