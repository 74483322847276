import React from 'react'

const SvgIndexTeamIcon1 = (props) => (
  <svg viewBox='0 0 41.4 40' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M40 3.55l-.53-.71a7.31 7.31 0 00-10.16-1.42L24.69 5 1.58 11a1.33 1.33 0 00-1.24.89c0 .18-.18.36-.18.71a9.46 9.46 0 001.24 6.78A13.48 13.48 0 005 22.93 12.23 12.23 0 007.45 29a14.47 14.47 0 004.8 3.73 9 9 0 001.24 3.2A8.61 8.61 0 0021.14 40a1.26 1.26 0 001.06-.53c.18-.18.36-.18.36-.36l11.38-21.87 4.62-3.37A7.58 7.58 0 0040 3.55zm-37.16 10l22.23-6 1.24 1.77-19.93 11.3A6.47 6.47 0 013.71 18a5.84 5.84 0 01-.89-4.44zM9.58 27.2a9.62 9.62 0 01-1.78-3.56c0-.17-.18-.53-.18-.71l20.09-11.55.89 1.24-15.46 17.6a15 15 0 01-3.56-3zm10.85 10a5.72 5.72 0 01-4.63-2.67 10 10 0 01-.89-2v-.35l15.47-17.43 1.07 1.43zM37 11.55l-3.6 2.67-6-8 3.55-2.67a4.74 4.74 0 016.4.89l.54.71a4.74 4.74 0 01-.89 6.4z'
        fill='#fff'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgIndexTeamIcon1
