import React, { useState, useRef, useEffect } from 'react'
import { FormattedMessage, changeLocale, useIntl, Link } from 'gatsby-plugin-react-intl'
import { useLocation } from '@reach/router'
import { Logo, MenuIcon } from '../icons'

import {
  HeaderContainer,
  LangSpan,
  LogoImageLink,
  MenuContainer,
  MoreTextContainer,
  RecruitmentListContainer,
  SideBarContainer,
  SideBarLink,
  SplitLine,
} from './header.atom'
import { LangType } from '../../constant'

const SideBarLinkList = [
  {
    title: 'menu_home',
    to: '/',
  },
  {
    title: 'menu_case',
    to: '/case.html',
  },
  {
    title: 'menu_about',
    to: '/about.html',
  },
  {
    title: 'menu_join',
    to: 'https://rayvision.zhiye.com/',
    onlyCn: true,
    rel: 'nofollow',
  },
]

const RecruitmentList = [
  {
    link: 'https://rayvision.zhiye.com/',
    name: '加入瑞云',
    rel: 'nofollow',
  },
  {
    link: '/recruitment.html',
    name: '社会招聘',
  },
  {
    link: '/campus-recruitment.html',
    name: '校园招聘',
  },
]

const Header = React.memo(() => {
  const [showSideBar, handleShowSideBar] = useState(false)
  const intl = useIntl()
  const LangText = {
    [LangType.EN]: '中文',
    [LangType.CN]: 'English',
  }[intl.locale]
  const location = useLocation()
  const recruitmentPage = RecruitmentList.find((info) => info.link === location.pathname)
  const handleChangeLang = () => {
    changeLocale(intl.locale === LangType.CN ? LangType.EN : LangType.CN)
  }

  const headerRef = useRef(null)
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [])

  const handleScroll = (e) => {
    if (headerRef.current) {
      if (e.target.scrollTop) {
        headerRef.current.style.backgroundColor = 'rgba(0,0,0,0.9)'
      } else {
        headerRef.current.style.backgroundColor = 'transparent'
      }
    }
  }
  return (
    <HeaderContainer ref={headerRef} isRecruitmentPage={!!recruitmentPage}>
      <LogoImageLink to='/' alt='rayvision'>
        <Logo />
      </LogoImageLink>
      <MenuContainer>
        {recruitmentPage && (
          <RecruitmentListContainer>
            {RecruitmentList.map((info, index) => (
              <Link
                rel={info.rel}
                to={info.link}
                key={index}
                className={info.link === location.pathname ? 'actived' : ''}
              >
                {info.name}
              </Link>
            ))}
          </RecruitmentListContainer>
        )}
        <MoreTextContainer
          onMouseEnter={() => {
            handleShowSideBar(true)
          }}
          onTouchEnd={(e) => {
            handleShowSideBar(true)
            e.stopPropagation()
          }}
          onMouseLeave={() => {
            handleShowSideBar(false)
          }}
          isRecruitmentPage={!!recruitmentPage}
        >
          <MenuIcon />
          <span>
            <FormattedMessage id='menu_more' />
          </span>
          <SideBarContainer show={showSideBar}>
            {SideBarLinkList.map((info, index) => {
              return (
                (intl.locale === LangType.CN || !info.onlyCn) && (
                  <SideBarLink key={info.title} to={info.to} rel={info.rel}>
                    <FormattedMessage id={info.title} />
                  </SideBarLink>
                )
              )
            })}
          </SideBarContainer>
        </MoreTextContainer>
        {!recruitmentPage && <SplitLine />}
        {!recruitmentPage && <LangSpan onClick={handleChangeLang}>{LangText}</LangSpan>}
      </MenuContainer>
    </HeaderContainer>
  )
})

export default Header
